import React from 'react';
import PropTypes from 'prop-types';

// Components
import { graphql } from 'gatsby';
import {
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Item,
} from 'semantic-ui-react';
import PostLink from '../components/post-link';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tag}"`;

  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />);

  return (
    <Container>
      <Breadcrumb size="massive">
        <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/blog">Blog</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/blog/tags">Tags</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{tag}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider horizontal>
        <Header as="h3">
          <Icon name="pin" />
          {tagHeader}
        </Header>
      </Divider>
      <Item.Group divided className="blog-post-items">
        {Posts}
      </Item.Group>
    </Container>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }).isRequired,
};

export default Tags;

// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerptAst(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            datePath: date(formatString: "YYYY/MM/DD")
            slug
            title
            tags
          }
        }
      }
    }
  }
`;
